<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Attendance Report
            <v-spacer></v-spacer>
            <print-buttons
              :passAuthToReport="true"
              labelPdf="Download Pdf"
              labelExcel="Download Excel"
              :downloadUrl="download_url"
              :pdf="false"
              :excel="true"
            ></print-buttons>
          </v-card-title>
          <v-card>
            <v-card-title outlined>
              <v-row no-gutters>
                <v-col md="10">
                  <v-row no-gutters>
                    <v-col md="3">
                      <v-flex>
                        <v-calendar-field
                          class="attendanceDate"
                          id-val="attendance_date"
                          v-model="attendance_date"
                          label="Attendance Date"
                          style="margin-top:0px !important"
                        >
                        </v-calendar-field>
                      </v-flex>
                    </v-col>
                    <v-col md="3">
                      <v-flex>
                        <v-grade-field
                          v-model="gradeId"
                          :inside="true"
                          @selectedGrade="gradesSection"
                          :with-all="true"
                        ></v-grade-field>
                      </v-flex>
                    </v-col>
                    <v-col md="3">
                      <v-flex>
                        <v-subject-field
                          v-model="subjectId"
                          :gradeId="gradeId"
                          :subjects="subjects"
                        ></v-subject-field>
                      </v-flex>
                    </v-col>
                    <v-col md="3">
                      <v-flex>
                        <v-section-field
                          :sections="sections"
                          v-model="sectionId"
                        ></v-section-field>
                      </v-flex>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col md="2" style="margin-top:15px">
                  <v-flex>
                    <search-button
                      :disabled="!gradeId"
                      permission="subject-read"
                      @action="get()"
                      >Search</search-button
                    >
                  </v-flex>
                </v-col>
              </v-row>
            </v-card-title>
          </v-card>
          <br />
          <v-data-table
            :headers="headers"
            :hide-default-footer="attendanceForm.data.length < 1"
            :items="items"
            :footer-props="footerProps"
            :options.sync="pagination"
            :loading="attendanceForm.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr>
                <td>{{ index + 1 }}</td>
                <td class="text-xs-left">{{ item.joined_at }}</td>
                <td class="text-xs-left">{{ item.left_at || "-" }}</td>
                <td class="text-xs-left">{{ item.status || "-" }}</td>
                <td class="text-xs-left">{{ item.sub_name || "-" }}</td>
                <td class="text-xs-left">
                    <v-btn small color="success" @click="showModal()">Activities</v-btn>
                </td>
                <!-- <td class="text-xs-left"> 
                  <li v-for="(i,v) in item.activities" :key="v"  style="list-style-type:none;">
                    {{i.message}} {{i.timestamp}}
                  </li>
                </td> -->
              </tr>
            </template>
            <v-alert
              slot="no-results"
              :value="true"
              color="error"
              icon="warning"
            >
              Your search found no results.
            </v-alert>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog v-model="show_modal" persistent max-width="700">
      <v-card>
        <v-card-title class="title pa-3 warning white--text">
          <div style="margin-right:auto;">
            <!-- <v-icon class="mr-2 white--text">info</v-icon> -->
            Students Activities
          </div>
          <div
            id="close_whats_new_modal"
            @click="closeModal()"
            style="margin-left:auto;float:right;cursor:pointer;"
          >
            <v-icon color="white">close</v-icon>
          </div>
        </v-card-title>
        <v-card-text class="ma-0">
           <v-timeline align-top dense>
             <div v-for="(item,key) in activities" :key="key">
              <v-timeline-item v-for="(i,key) in Object.keys(item)" :key="key" small 
              :color="serviceColor(i)">
              <!-- {{i}}{{key}} -->
                  <v-row class="ml-2 pt-1">
                    <v-col class="pt-0" cols="3">
                      {{i.toUpperCase()}}
                    </v-col>
                    <v-col class="pt-0" >
                      <ul v-for="(m,k) in item[i]" :key="k">
                        <li>
                          {{ ++k }}) {{m.message}}
                        </li>
                      </ul>
                    </v-col>
                  </v-row>

              </v-timeline-item>

             </div>

          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";
// import WhatsNew from "@/components/whatsnew";
// import WhatsNew from '../../../../components/whatsnew/index.vue'
import { passDataToExternalDomain } from "../../../../library/helpers";
import VSubjectField from "../../../../components/app/VSubjectField.vue";
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
export default {
  mixins: [Mixins],
  components: {
    VSubjectField
    // GradeSectionFilter,
  },
  data: () => ({
    show_modal:false,
    attendance_date: nd.format("YYYY-MM-DD"),
    download_url: "",
    attendanceForm: new Form({}, "/api/report/zoom-attendance"),
    downloadColumns: [
      { value: "joined_time", name: "Joined Time", selected: true },
      { value: "left_time", name: "Left Time", selected: true },
      { value: "status", name: "Status", selected: true },
      { value: "subject_name", name: "Subject Name", selected: true },
    ],
    headers: [
      { text: "#", align: "left", value: "sno", sortable: false, width: 20 },
      {
        text: "Joined Time",
        align: "left",
        value: "joined_time",
        sortable: false,
      },
      { text: "Left Time", align: "left", value: "left_time", sortable: false },
      { text: "Status", align: "left", value: "status", sortable: false },
      {
        text: "Subject Name",
        align: "left",
        value: "subject_name",
        sortable: false,
      },
      {
        text: "Activities",
        align: "left",
        value: "subject_name",
        sortable: false,
      },
    ],
    items: [],
    gradeId: "",
    subjectId: "",
    sectionId: "",
    sections: [],
    subjects: [],
    pagination: {
      itemsPerPage: 25,
    },
    columns: [],
    activities:[],
  }),
  watch: {
    pagination: function() {
      this.get();
    },
    gradeId: function() {
      this.sectionId = "";
    },
    sectionId: function() {
      //   this.get();
    },
  },
  computed: {},
  mounted() {},
  methods: {
    serviceColor(item){
      console.log(item)
      if(item === "present"){
        return 'green';
      }else{
        return 'red';
      }

    },
    showModal(){
      this.show_modal = true;
    },
    closeModal(){
      this.show_modal = false;
    },
    dynamicColumns() {
      // let columns = [];
      this.downloadColumns.map((item) => {
        this.columns.push(item);
      });
      // console.log(columns)
      return columns;
    },
    gradesSection(grade) {
      this.sections = grade.sections;
      this.getSubjects(grade);
    },
    getSubjects() {
      this.$rest.get("/api/subject?grade=" + this.gradeId).then(({ data }) => {
        this.subjects = data.data.map((item) => {
          return { id: item.id, text: item.name };
        });
      });
    },
    get() {
      let myarray = [];
      if (!this.gradeId) {
        return;
      }
      let query = this.queryString(
        `gradeId=${this.gradeId}&sectionId=${this.sectionId}&attendance_date=${this.attendance_date}&subject_id=${this.subjectId}`
      );

      this.attendanceForm
        .get(null, query)
        .then((res) => {
          this.items = res.data.data;
          this.download_url = res.data.download_url;
          const present = res.data.data[0]['activities'].filter(word => word.status === 'present');
          const leave = res.data.data[0]['activities'].filter(word => word.status === 'leave');
          this.activities.push({
            present:present,
            leave:leave
          })
       
        })
        .catch((err) => {});
    },
    downloadAttendance({ type, columns, columnString }) {
      // this.dynamicColumns();
      this.$rest.get("/api/download/zoom-attendance").then(({ data }) => {
        console.log("here", data);
        let url =
          data.data.download_url +
          "?gradeId=" +
          this.gradeId +
          "&sectionId=" +
          this.sectionId +
          `&flag=all&type=${type}&column=${columnString}`;
        passDataToExternalDomain("reporting");
        setTimeout(function() {
          window.open(url);
        }, 500);
      });
    },
  },
};
</script>
<style>
li{
  list-style-type: none;
}
.attendanceDate {
  margin-top: 0px !important;
}
.rate {
  position: absolute;
  margin-top: -20px;
  margin-left: 10px;
  color: gray;
  font-size: 12px;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  font-size: 14px !important;
}
.v-menu__content .v-input--selection-controls {
  margin-top: 0 !important;
}
</style>
